import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 100" role="img" width="200" height="200">
  <title>IC Logo</title>
  

  <g transform="translate(15, 10)">

    <rect x="10" y="10" width="15" height="80" fill="black" />


    <path d="
      M 70,15
      A 35,35 0 1,0 70,85
      H 55
      A 20,20 0 1,1 55,15
      Z
    " fill="black" />
  </g>
</svg>
);

export default IconLogo;
