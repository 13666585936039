import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    { category: 'Programming Languages', items: ['Python', 'Java', 'C++', 'PHP', 'R'] },
    { category: 'Machine Learning', items: ['PyTorch', 'TensorFlow', 'Scikit-Learn', 'Keras'] },
    { category: 'Data Management', items: ['SQL', 'MySQL', 'Oracle', 'MongoDB'] },
    { category: 'Network Infrastructure', items: ['Splunk', 'SolarWinds', 'Obkio'] },
    { category: 'Web Development', items: ['CakePHP', 'FastAPI', 'Django', 'Node.js'] },
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Yunfan Cai, and I’m passionate about leveraging technology to solve
              real-world problems. My journey in computer science began with a fascination for
              programming languages and grew into a deep expertise in artificial intelligence,
              machine learning, and data science. Over the years, I've had the privilege of working
              in healthcare, IT networks, and software development, where I honed my skills in
              building innovative solutions and improving operational efficiency.
            </p>

            <p>
              Today, my focus is on creating impactful, data-driven applications and systems.
              Whether it's developing neural networks for image classification, optimizing IT
              infrastructures, or ensuring data accuracy in healthcare settings, I am driven by the
              challenge of turning complex problems into streamlined, efficient solutions.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills &&
              skills.map((skillCategory, i) => (
                <li key={i}>
                  <strong style={{ fontSize: '18px', color: '#00FFAB' }}>
                    {skillCategory.category}:
                  </strong>
                  <span style={{ fontSize: '16px', color: '#FFFFFF' }}>
                    {' '}
                    {skillCategory.items.join(', ')}
                  </span>
                </li>
              ))}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
